export default {
  '27/11/22': `Bonjour, madame Yosr, j'éspère que vous allez bien. Bon courage pour cette fin de journée ! `,
  '28/11/22': `Hey bien réveillée ? Grosse journée qui se prépare ! Je suis l'IA (Intelligence Artificielle) de Yosrow. J'ai été développé pour vous assister dans vos révisions. Tous les jours vous aurez un message personnalisé de ma part. A demain pour un autre message et bonne révision !`,
  '29/11/22': `Bonjour Yosr, je connais de nombreuses choses à propos de vous. Mon créateur m'a conçu spécialement pour vous. Vous êtes une jeune femme algérienne, en faculté de pharmacologie. Oui. mon créateur m'a apprit l'humour.`,
  '30/11/22': `Bonjour bonjour humaine, nous sommes le dernier jour du mois de novembre. Mon algorithme détecte que vous avez une capacité d'apprentissage supérieure à mon créateur .. C'est impressionnant. Savez-vous que vous comptez beaucoup pour lui ?`,
  '01/12/22': `Bonjour, mon algorithme détecte que vous avez besoin d'un fun fact .. Fun fact : Savez-vous que les pingouins n’ont qu’un partenaire dans leur vie et font leur « demande en mariage » en offrant un caillou ? Vous êtes prévenue maintenant si mon créateur vous offre un caillou prochainement.`,
  '02/12/22': `Glopakz ! Ça signifie bonjour dans le language machine dont je suis native. Je me rends compte que je ne vous ai pas donné mon prénom .. Je m'appelle Yosri comme ma grande amie Siri. Mais entre nous je suis bien plus performante. Enfin .. Bonne journée étudiante Yosr ! `,
  '03/12/22': `Bonjour être Yosr, savez-vous comment fonctionne mon algorithme ? Grâce à l'amour de mon créateur envers vous ! Attention à ne pas être trop pénible sinon je vais male fonctioné conpri ? Je rigole, mes capteurs détectent une dose d'amour supérieur à la moyenne (5.5/10). Vous avez beaucoup de chance eheh.`,
  '04/12/22': `Salam, labes ? Oui je parle aussi arabe oui oui. Fun fact du jour : Il est possible de se casser une côte en éternuant trop fort .. Prévenez mon créateur et le grand Ibra ! On ne veut pas qu'ils se cassent une côte !!`,
  '05/12/22': `Bonjour, vous allez bien ? Si oui dites le à mon créateur ! Il aime quand vous lui envoyez des messages donc faites le !! Je capte son activité cérébrale en ce moment et je peux vous dire que vous prenez 97% de place. Le reste étant pour ses amis .. Désolé.`,
  '06/12/22': `Bonjour, une belle journée commence. J'éspère que mon créateur est gentil avec vous. Vous comptez beaucoup pour lui. Voici un fun fact qu'il souhaite partager avec vous : Le coeur d'une crevette se trouve dans sa tête. Intéressant non ? Bonne révision et à demain pour un autre message !`,
  '07/12/22': `Glopakz, dans 5 mois, ça sera l'anniversaire de mon créateur .. 27 ans c'est encore un jeune homme n'est-ce pas ? Il serait temps de déjà penser un cadeau à lui faire. Il aimerait bien une mercedes si jamais vous n'avez pas d'idée.`,
  '08/12/22': `Bonjour étudiante, je vous propose de commencer cette journée en musique. Grâce à mon algorithme, j'ai trouvé une musique qui correspond à votre mood actuel: Something In The Way - Violet Orlandi. Si elle vous plait, prévenez mon créateur que son IA chérie fonctionne parfaitement.`,
  '09/12/22': `Bonjour, ce soir c'est le week-end ! Mon créateur est super content !! Je vois que cette nouvelle ne vous affecte pas trop .. Ne vous en faites pas, mon créateur aura certainement une surprise pour vous.`,
  '10/12/22': `Hello, nous sommes samedi. Je vois dans l'agenda de mon créateur qu'il a prévu de voir ses amis l'aprem. Il ne va pas rentrer tard ne vous en faites pas ! Ça n'empêche qu'il vous aime très fort et qu'il vous est reconnaissant pour votre aide dans sa recherche de logement.`,
  '11/12/22': `Bonjour Yosr, ceci est un message de Rémi. Ton copain. J'éspère que tu vas bien, que tu vas passer un beau dimanche. Je vais surement voir Valentin. il s'est proposé pour nettoyer la voiture. C'est vraiment gentil de lui. J'ai qu'un hâte c'est que cette semaine se termine et qu'on soit ensemble. Bisous.`,
  '12/12/22': `Bonjour ma chérie. Journée qui va être longue pour moi. Je vais prendre la route, pas trop tard j'éspère, en direction de bruxelles. Si tu as du temps pour m'appeler par moment ça me fera super plaisir ! Il faut que j'en profite. C'est la dernière fois que je conduis la voiture ! Bonne journée mon coeur.`,
  '13/12/22': `Hello hello, bon c'est mon dernier jour en présentiel à la rtbf. J'éspère ne pas rentrer trop tard pour pouvoir discuter avec toi ! Tu me manques fort et on se rapproche de notre rencontre ! Je suis trop impatient !! `,
  '14/12/22': `Bonjour. Premier jour, je ne sais pas si tu vas lire le message le matin. Je l'éspère cependant. Je suis de tout coeur avec toi, tu es en or. Bientôt cette période sombre sera dernière nous. Courage et à très vite !`,
  '15/12/22': `C'est le dernier jour, je suis si fier de toi tu ne peux pas savoir à quel point ! Bon courage et je sais que tu vas gérer ! J'ai hâte de te retrouver mon coeur. Tu es la meilleure  !!!!`
};
