
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TimerSelect',
  methods: {
    selectType(type: string) {
      this.$emit('selectedType', type);
    }
  }
});
