
import { defineComponent } from 'vue';
import TimerContainer from './containers/TimerContainer.vue';

export default defineComponent({
  name: 'App',
  components: {
    TimerContainer
  }
});
