
import { defineComponent } from 'vue';
import HeaderBar from '@/components/HeaderBar.vue';
import DailyMessage from '@/components/DailyMessage.vue';
import TimerComponent from '@/components/TimerComponent.vue';
import TimerHistory from '@/components/TimerHistory.vue';
import { useHistoryStore } from '@/stores/history';
import { mapStores } from 'pinia';
import moment from 'moment';

export default defineComponent({
  name: 'TimerContainer',
  components: { HeaderBar, TimerComponent, TimerHistory, DailyMessage },
  mounted() {
    if (this.historyStore.getTodayDate !== this.todayDate) {
      this.historyStore.setTodayDate(this.todayDate);
      this.historyStore.clearRecap();
      this.historyStore.clearTotal();
      this.historyStore.setMessageRead(false);
    }
  },
  computed: {
    ...mapStores(useHistoryStore),
    todayDate(): string {
      return moment(new Date()).format('DD/MM/YY');
    }
  }
});
