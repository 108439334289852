import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59f7b0da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "timer-container" }
const _hoisted_2 = { class: "timer-app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderBar = _resolveComponent("HeaderBar")!
  const _component_DailyMessage = _resolveComponent("DailyMessage")!
  const _component_TimerComponent = _resolveComponent("TimerComponent")!
  const _component_TimerHistory = _resolveComponent("TimerHistory")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderBar),
    (!_ctx.historyStore.messageRead)
      ? (_openBlock(), _createBlock(_component_DailyMessage, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TimerComponent),
      _createVNode(_component_TimerHistory)
    ])
  ]))
}