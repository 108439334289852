
import { defineComponent } from 'vue';
import { useHistoryStore } from '@/stores/history';
import { mapStores } from 'pinia';
import moment from 'moment';

export default defineComponent({
  name: 'TimerHistory',
  computed: {
    ...mapStores(useHistoryStore)
  },
  methods: {
    formatDuration(seconds: number): string {
      const formattedSeconds = moment.utc(seconds * 1000);
      let formattedTime = '';
      if (seconds < 3600)
        formattedTime = formattedSeconds.format('m [minutes]');
      if (seconds >= 3600 && seconds < 7200)
        formattedTime = formattedSeconds.format('H [hour] m [minutes]');
      if (seconds >= 7200)
        formattedTime = formattedSeconds.format('H [hours] m [minutes]');
      formattedTime = formattedTime.split(' 0 minutes')[0];
      return formattedTime;
    },
    formatDate(date: Date) {
      return moment(date).format('HH:mm');
    }
  }
});
