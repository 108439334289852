import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03deb175"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "timer-component" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TimerCounter = _resolveComponent("TimerCounter")!
  const _component_TimerSelect = _resolveComponent("TimerSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.timerType)
      ? (_openBlock(), _createBlock(_component_TimerCounter, {
          key: 0,
          colors: _ctx.typeColors,
          timerType: _ctx.timerType,
          onSelectedType: _ctx.selectType
        }, null, 8, ["colors", "timerType", "onSelectedType"]))
      : (_openBlock(), _createBlock(_component_TimerSelect, {
          key: 1,
          onSelectedType: _ctx.selectType
        }, null, 8, ["onSelectedType"]))
  ]))
}