
import { defineComponent } from 'vue';
import CircleProgress from 'vue3-circle-progress';
import moment from 'moment';
import { useHistoryStore } from '@/stores/history';
import { mapStores } from 'pinia';
import { Recap } from '@/types/historyTypes';

import alarm from '@/assets/sounds/alarm.mp3';

export default defineComponent({
  name: 'TimerCounter',
  components: { CircleProgress },
  props: {
    colors: { type: Object, required: true },
    timerType: { type: String, required: true }
  },
  data: () => ({
    windowWidth: window.innerWidth as number,
    duration: 0 as number,
    durationLeft: 0 as number,
    timerInterval: 0 as number,
    isPlaying: false as boolean,
    alarm: new Audio(alarm)
  }),
  computed: {
    ...mapStores(useHistoryStore),
    circleSize(): number {
      return this.windowWidth < 500 ? 250 : 400;
    },
    percentLeft(): number {
      return (this.durationLeft / this.duration) * 100;
    },
    formattedDurationLeft(): string {
      const formattedSeconds = moment.utc(this.durationLeft * 1000);

      if (this.durationLeft >= 3600) return formattedSeconds.format('HH:mm:ss');

      return formattedSeconds.format('mm:ss');
    },
    durationIsOver(): boolean {
      return this.durationLeft <= 0;
    }
  },
  methods: {
    startTimer() {
      if (this.durationIsOver) return false;

      clearInterval(this.timerInterval);
      this.isPlaying = true;

      this.timerInterval = setInterval(() => {
        this.durationLeft--;

        if (this.durationIsOver) {
          this.endTimer();
          this.alarm.play();
        }
      }, 1000);
    },
    pauseTimer() {
      clearInterval(this.timerInterval);
      this.isPlaying = false;
    },
    endTimer() {
      clearInterval(this.timerInterval);

      const duration = this.duration - this.durationLeft;

      this.duration = 0;
      this.durationLeft = 0;
      this.isPlaying = false;

      if (duration < 60) return false;

      this.saveRecap(duration);
      this.saveTotal(duration);
    },
    increaseTime(value: number) {
      this.duration += value;
      this.durationLeft += value;
    },
    decreaseTime(value: number) {
      this.duration -= value;
      this.durationLeft -= value;

      if (this.durationIsOver) this.endTimer();
    },
    saveRecap(duration) {
      const recap = {
        at: new Date(),
        for: duration,
        type: this.timerType
      } as Recap;

      this.historyStore.addRecap(recap);
    },
    saveTotal(duration) {
      this.historyStore.addToTotal(duration, this.timerType);
    },
    returnToSelection() {
      this.endTimer();
      this.$emit('selectedType', '');
    }
  }
});
