
import { defineComponent } from 'vue';
import { useHistoryStore } from '@/stores/history';
import { mapStores } from 'pinia';
import dailyMessages from '@/assets/data/dailyMessages';

export default defineComponent({
  name: 'DailyMessage',
  data: () => ({
    dailyMessages: dailyMessages
  }),
  computed: {
    ...mapStores(useHistoryStore),
    dailyMessage() {
      return dailyMessages[this.historyStore.getTodayDate];
    }
  },
  methods: {
    closeDailyMessage() {
      this.historyStore.setMessageRead(true);
    }
  }
});
