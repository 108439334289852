import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78d95848"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "timer-select" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "spartan-font text-lg blue-button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectType('study')))
    }, " Study time "),
    _createElementVNode("button", {
      class: "spartan-font text-lg red-button",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectType('rest')))
    }, " Rest time ")
  ]))
}